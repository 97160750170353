import React, { FC } from 'react';
import { PageLayout, Faq } from 'components';
import { graphql } from 'gatsby';
import { SeoType } from '../pages/index';

interface FAQPageProps {
  data: {
    FaqPageQuery: {
      title: string;
      seo: SeoType;
      faq: {
        id: number;
        question: string;
        answer: string;
      }[];
    };
  };
}
const FaqPage: FC<FAQPageProps> = ({ data }) => {
  const { FaqPageQuery } = data;
  const { title, seo, faq } = FaqPageQuery;
  return (
    <PageLayout
      title={title}
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.publicURL}
      className="container"
    >
      <Faq faq={faq} faqheading={title} />
    </PageLayout>
  );
};

export default FaqPage;
export const query = graphql`
  query FaqQuery {
    FaqPageQuery: strapiFaqPage {
      title
      seo {
        metaDescription
        metaTitle
        shareImage {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 400, height: 400)
          }
        }
      }
      faq {
        id
        question
        answer
      }
    }
  }
`;
